/*
* @Author: jack
* @Date:   2021-12-14 12:23:33
* @Last Modified by:   jack
* @Last Modified time: 2023-01-17 22:15:32
*/
const TokenKey = 'shao-manage-expire-token-qirui'

export function getToken() {
  if(process.env.NODE_ENV == "development") {
  	setToken(process.env.VUE_APP_TOKEN)
    return process.env.VUE_APP_TOKEN
  }
  return getExpire(TokenKey)
}

export function setToken(token) {
	removeToken()
  return setExpire(TokenKey, token, 60 * 60 * 2 * 1000)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

function setExpire(key,value, expire){
    let obj = {
        data: value,
        time: Date.now(),
        expire: expire
    };
    localStorage.setItem(key, JSON.stringify(obj));
}
//获取本地缓存
function getExpire(key){
    let val = localStorage.getItem(key);
    if (!val) {
        return val;
    }
    val = JSON.parse(val);
    if (Date.now() - val.time > val.expire) {
        localStorage.removeItem(key);
        return null;
    }
    return val.data;
}
