/*
* @Author: jack
* @Date:   2021-12-14 12:27:50
* @Last Modified by:   jack
* @Last Modified time: 2022-01-11 21:11:32
*/
import axios from 'axios'
import { Toast, Dialog } from 'vant';
import { getToken, removeToken } from '@/utils/auth'
import { removeUser } from '@/utils/user'
import { oauthUrl, getAppId } from '@/utils/app'

const API_ROOT = process.env.VUE_APP_AXIOS_URL
// create an axios instance
const service = axios.create({
  baseURL: API_ROOT, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    //add app_id
    // if(store.state.app) {
    //   let app_id = store.state.app.id
    //   if(app_id)config.data = {app_id, ...config.data}
    //   if(config.method == 'get') {
    //     config.params = {app_id, ...config.data}
    //   }
    // }
    // config.headers['Content-Type'] = 'application/json'
    config.url += '?v=' + process.env.VUE_APP_Version
    let token = getToken()
    if(!token)token = ''
    config.data = {token, ...config.data}

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    console.log(res)
    // if the custom code is not 20000, it is judged as an error.
    if (res.code == 401) {
      removeToken()
    	window.location.href = oauthUrl(getAppId())
    	return Promise.reject()
    }else if(res.code == 400){
    	Toast({
	        message: res.message,
	        onClose: data=> {
            if(res.message.indexOf('刷新')>=0) {
              location.reload()
            }
	          // window.location.href = oauthUrl()
	        }
	    })
    }else if(res.code == 200){
    	return res.data
    }else if(res.code == 201){
    	return res
    }else{
    	Toast({
	        message: '当前人数过多，请稍后再试',
	        onClose: data=> {
	          // window.location.href = oauthUrl()
	        }
	    });
    }
  },
  error => {
    console.log(error) // for debug
    if(error.response) {
      Toast('当前人数过多，请稍后再试');
    }else{
      Toast('网络错误，请关闭页面，重新打开');
    }
    return Promise.reject(error)
  }
)

export default service
