<template>
  <div class="index">
  	<h3>奇瑞贺新春</h3>
    <loading size="24px" vertical style="margin-top:10%;">加载中...</loading>
  </div>
</template>

<script>
import { getInfo, getAct, getUserInfo } from '@/api/app'
import { setToken, getToken } from '@/utils/auth'
import { setUser } from '@/utils/user'
import { setApp } from '@/utils/app'

import { getQueryVariable, getIndexUrl } from '@/utils/app'
import { Loading } from 'vant';
import 'vant/lib/loading/style';

export default {
  name: 'Home',
  components: {
  	Loading
  },
  created () {
  	console.log(this.$route)
  	getAct(this.$route.query.id).then(res=> {
  		if(!res)return
  		setApp(res)
  		const token = getToken()
  		if(!token) {
  			getInfo({code: getQueryVariable('code')}).then(res=> {
          setToken(res.token)
          setUser(res.user)
		  		getIndexUrl()
		  		this.$router.push({name: 'Home', query: {id: this.$route.query.id}})
		  	})
  		}else{
        getUserInfo().then(res=> {
          setUser(res)
          this.$router.push({name: 'Home', query: {id: this.$route.query.id}})
        })
  		}
  	})
  }
};
</script>
<style>
.index{
  background: url(../assets/bg.jpg) no-repeat center;
  background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
}
.index h3{ margin-top: 30%; color: #fff; font-size: 30px; }
.index .van-loading__text{ color:#fff; font-size:16px;}
</style>
