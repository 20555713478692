/*
* @Author: jack
* @Date:   2021-12-14 21:43:16
* @Last Modified by:   jack
* @Last Modified time: 2023-01-18 15:47:32
*/
import request from '@/utils/request'

export function getAct(id) {
  return request({
    url: 'activity/' + id,
    method: 'get'
  })
}


export function getInfo(data) {
  return request({
    url: 'login',
    method: 'post',
    data
  })
}

export function share(data) {
  return request({
    url: 'share',
    method: 'post',
    data
  })
}

export function getAcitvityInfo(data) {
  return request({
    //url: 'acitvity/index',
    url: 'json'+process.env.VUE_APP_PATH+'.json',
    method: 'get',
    data
  })
}

export function prize(data) {
  return request({
    url: 'user/prize',
    method: 'post',
    data
  })
}

export function getResultPrize(data) {
  return request({
    url: 'user/getPrize',
    method: 'post',
    data
  })
}

export function sendMessage(data) {
  return request({
    url: 'user/sendMsg',
    method: 'post',
    data
  })
}

export function userInfo(data) {
  return request({
    url: 'user/info',
    method: 'post',
    data
  })
}

export function getPrizeLog(id) {
  return request({
    url: 'user/getPrizeLog/' + id,
    method: 'post'
  })
}

export function getOrder(data) {
  return request({
    url: 'user/getOrder',
    method: 'post',
    data
  })
}

export function saveOrder(data) {
  return request({
    url: 'user/saveOrder',
    method: 'post',
    data
  })
}

export function getUserInfo(data) {
  return request({
    url: 'user/getUserInfo',
    method: 'post',
    data
  })
}
