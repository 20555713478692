<template>
  <div class="home" >
    
  	<div class="jilu" :v-wechat-title="info.name" @click="getResult">
      <img width="60%" src="../assets/logo.png" align="left" style="margin: 10px; display: none;" />
      <img width="20%" src="../assets/jilu.png" align="right" />
    </div>
    <img alt="奇瑞贺新春" :src="require('../assets/' + id +'.png')" width="50%" class="logo">
    <div class="act">
      <p style="color:#fbd7b8; font-weight: 800;line-height: 18px;">活动时间：{{info.start_date}} - {{info.end_date}}</p>
    	<p style="color:#fbd7b8; font-weight: 800;line-height: 18px; font-size: 10px;">每日10：00准时抽奖，每日奖品数量有限，抽完即止</p>
    </div>
    <div class="button">
    	<van-button @click="guize()" type="primary" round color="linear-gradient(to right, #facd8a, #f9cda1)" class="button-color"><b>活动规则</b></van-button>
    	<van-button type="primary" @click="handleStart" round color="linear-gradient(to right, #facd8a, #f9cda1)" class="button-color">参与抽奖</van-button>
    </div>
    <div class="list-prize" v-if="info && info.prizes">
    	<li v-for="item in info.prizes">{{item.ffname}}</li>
    </div>
    <div class="tuzi">
      <img src="../assets/tuzi.png" align="center"/>
    </div>
    <van-notice-bar v-if="false" class="beizhushow"
      left-icon="volume-o"
      text="【尊敬的客户：您好，春节期间多家快递公司物流停运，故自1月20日至1月30日将暂停寄送“实物礼品”，待快递公司正常运营后邮寄（预计春节假期后）。恭祝您新春快乐，阖家幸福！】"
    />
    <van-popup v-model="mobileShow" class="mobile-width">
    	<div class="form-body">
    	<h2 class="form-title">请完善手机号</h2>
    	<van-form @submit="onSubmit" class="form-mobile">
			  <van-field
			    v-model="mobile"
			    name="mobile"
			    label="手机号"
			    placeholder=""
			    :rules="[{ required: true, message: '请填写手机号' }]"
			  />
			  <van-field
				  v-model="code"
				  center
				  clearable
				  name="code"
				  label="短信验证码"
          maxlength="5"
          @input="limitNumber"
				  placeholder=""
				  :rules="[{ required: true, message: '请填写验证码' }]"
				>
				  <template #button>
				    <van-button size="small" v-if="ok" type="primary" native-type="button" color="#ee4e56"><van-count-down :time="time" format="ss" @finish="finish" /></van-button>
				    <van-button size="small" v-else type="primary" native-type="button" @click="sendMsg" color="#ee4e56">发送验证码</van-button>
				  </template>
				</van-field>
        <div  style="" v-if="mobileShow"><div <div class="l-captcha savemobile" data-width="100%" id="nc" data-site-key="737e78768d3f54778e102d8be93a17a6" data-callback="getResponse" ref="luosimao"><loading v-if="showOkLuomao" /></div></div>
			  <div style="margin: 16px;">
			    <van-button round block type="info" native-type="submit" color="#ee4e56">提交</van-button>
			  </div>
			</van-form>
			</div>
    </van-popup>
    <van-popup v-model="infoShow" class="mobile-width" @click-overlay="clickOverlay">
    	<div class="form-body">
    	<h2 class="form-title">请完善收货地址</h2>
      <van-address-edit
          :area-list="areaList"
          show-search-result
          :search-result="searchResult"
          :area-columns-placeholder="['请选择', '请选择', '请选择']"
          @save="onSubmitInfo"
          @change-detail="onChangeDetail"
          save-button-text="保存"
          :address-info="addressInfo"
      />
      <van-notice-bar class="beizhushow"
        left-icon="volume-o"
        text="提醒：收货地址提交后，无法修改，如因地址填写错误导致的退货费用由客户本人承担"
      />
			</div>
    </van-popup>

    <van-popup v-model="prizeShow" class="mobile-prize" @closeable="true">
    	<div class="zhong" @click="prizeClosed">
    		<img src="../assets/z.png?v=1" width="100%">
    		<div class="pname" v-if="prize && prize.result">{{prize.result.name}}</div>
    		<div class="fname" v-if="prize  && prize.result">{{prize.result.fname}}</div>
    	</div>
    </van-popup>

    <van-popup v-model="logShow" class="mobile-log-show">
    	<h2 class="form-title">中奖记录</h2>
    	<div class="log-show" v-if="logPrize && logPrize.prize">
        <div v-if="logPrize.prize.name.indexOf('谢谢')>=0">
          <font color="#ffb971">恭喜您获得：{{logPrize.prize.name}}</font>
        </div>
        <div v-else>
    		<p>恭喜您获得：<font color="#ffb971">{{logPrize.prize.ffname}}</font>，若没有收到请耐心等待，稍后系统会发放奖品</p>
    		<div v-if="logPrize.prize.red_amount == 0 && logPrize.row.result" class="wuliu">
          <p>物流编号：{{logPrize.row.result}}</p>
          <p v-if="logPrize.list && logPrize.list.data && logPrize.list.data.logisticsTraceDetailList" v-for="item in logPrize.list.data.logisticsTraceDetailList">{{item.time|timestampToTime}}:{{item.desc}}</p>
        </div>
	    	<van-button size="small" v-if="logPrize.prize.red_amount == 0 && !user.address" type="primary" native-type="button" @click="infoShow = true" color="#ee4e56">完善地址</van-button>
        </div>
    	</div>
    	<div v-else>暂无记录</div>
    </van-popup>

    <van-overlay :show="show" >
      <div class="wapp">
      <LuckyWheel
		    ref="myLucky"
		    width="300px"
		    height="300px"
		    class="myLucky"
		    :prizes="prizes"
		    :blocks="blocks"
		    :buttons="buttons"
		    @start="startCallback"
		    @end="endCallback"
		  />
      </div>
      <div class="daojishi">活动结束倒计时<van-count-down :time="endtime" format="DD 天 HH 时 mm 分 ss 秒" /></div>
      <div class="scroll-wrap">
			    <ul class="scroll-content" :style="{ top }"><li v-for="item in prizeList">{{item}}</li ></ul>
			</div>
    </van-overlay>
    <van-popup v-model="showNc" class="mobile-log-show">
      <div id="chouNc" class="l-captcha" data-width="100%" data-site-key="737e78768d3f54778e102d8be93a17a6" data-callback="getResponseChou"><loading v-if="showOkLuomao" /></div>
    </van-popup>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
// @ is an alias to /src
import { share, prize as prizeApi, getResultPrize, sendMessage, userInfo, getPrizeLog, getUserInfo } from '@/api/app'
import { getApp, getIndexUrl } from '@/utils/app'
import { getUser , setUser } from '@/utils/user'
import { getPrizes, p } from '@/utils/common'
import { LuckyWheel } from 'vue-luck-draw'
import { Button as VanButton, Dialog as VanDialog, Popup as VanPopup, Form as VanForm, Field as VanField, Overlay as VanOverlay, Loading, Toast, CountDown as VanCountDown, AddressEdit as VanAddressEdit, NoticeBar as VanNoticeBar} from 'vant'
import 'vant/lib/button/style'
import 'vant/lib/dialog/style'
import 'vant/lib/popup/style'
import 'vant/lib/form/style'
import 'vant/lib/field/style'
import 'vant/lib/overlay/style'
import 'vant/lib/loading/style'
import 'vant/lib/address-edit/style'
import 'vant/lib/notice-bar/style'
import areaList from '@/api/area.js';

export default {
  name: 'Home',
  data() {
  	return {
  		id: 1,
  		ok: false,
  		time: 60 * 1000,
  		info: getApp(),
  		user: getUser(),
      mobileShow: false,
  		showNc: false,
  		infoShow: false,
  		prizeShow: false,
  		logShow: false,
  		mobile: '',
  		code: '',
  		name: '',
  		address: '',
  		show: false,
  		prize: {},
      // blocks: [{ padding: '20px', background: '#ffb971', imgs: [{src: require('../assets/zp.png'), top: '2px'}]}],
  		blocks: [{ padding: '20px',background: '#ffb971', imgs: [{src: require('../assets/zhuanpan-1.png'),rotate: true, width: '100%', top: '0px' }]}],
      prizes: [],
      buttons: [
        { radius: '55px', background: '#ee5744' },
        { radius: '45px', background: '#fff' },
        {
          radius: '0px', background: 'none',
          pointer: true,
          imgs: [{src: require('../assets/start.png'), top: '-77px', width:'125px'}],
          // fonts: [{ text: '开始\n抽奖', top: '-20px', fontColor: '#fff' }]
        },
      ],
      prizeList: [],
      activeIndex: 0,
      endtime: 0,
      logPrize: {},
      addressInfo: {
        name: '',
        tel: '',
        country: '',
        province: '',
        city: '',
        county: '',
        areaCode: '',
        postalCode: '',
        addressDetail: '',
        isDefault: false
      },
      areaList,
      searchResult: [],
      nc: null,
      chnc: null,
      captchaIns: null,
      showOkLuomao: true,
      luosimaoData: ''
  	}
  },
  filters: {
    timestampToTime: function(timestamp) {
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        return Y+M+D+h+m+s;
    }
  },
  computed: {
    top() {
      return - this.activeIndex * 50 + 'px';
    }
  },
  mounted() {
    setInterval(_ => {
      if(this.activeIndex < this.prizeList.length) {
        this.activeIndex += 1;
      } else {
        this.activeIndex = 0;
      }
    }, 2000);
    // this.ncYan()
  },
  components: {
  	VanButton, VanPopup, VanForm, VanField, VanDialog, VanOverlay, LuckyWheel, Loading, VanCountDown, VanAddressEdit, VanNoticeBar
  },
  created () {
  	if(this.info.hasOwnProperty('id') && this.info.id >0) {
  		this.init()
  	}else {
  		this.$watch('info', res=> {
	  		this.init()
	  	})
  	}
    // this.infoShow = true
  	
  },
  methods: {
  	init() {
  		this.getShare()
  		document.title = this.info.name
  		this.prizes = getPrizes(this.info.prizes)
  		this.id = this.info.id
  		let startTime = new Date(); // 开始时间
      let endTime = new Date(this.info.end_date_time.replace(/\-/g, "/")); // 结束时间
      console.log(endTime)
  		this.endtime = endTime - startTime
  		
  		this.name = this.user.realname
  		this.address = this.user.address
      getUserInfo().then(res=> {
          setUser(res)
      })

      // if(this.info.id == 3) {
      //   this.blocks = [{ padding: '20px',background: '#ffb971', imgs: [{src: require('../assets/zhuanpan-2.png'),rotate: true, width: '100%', top: '0px' }]}]
      // }
  	},
    limitNumber(value){
      console.log(value)
      if(value.length > 5) {
        this.code = value.slice(0, 5)
      }
    },
  	prizeClosed (){
  		if(!this.user.address && this.prize.result.red_amount == 0 && this.prize.result.name.indexOf('谢谢')<0) {
  			this.infoShow = true
  		}else if(this.prize.result.name.indexOf('谢谢')<0){
  			VanDialog.alert({
          message: '领取成功！稍后系统会在12小时内发放现金红包',
        })
  		}
  		
  		this.prizeShow = false

  	},
  	startCallback () {
  				// 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play()
		      // 模拟调用接口异步抽奖
		  setTimeout(() => {
		        // 假设后端返回的中奖索引是0
		  	var index = this.prize.index
        console.log(index)
        // index = 7
		        // 调用stop停止旋转并传递中奖索引
		  	this.$refs.myLucky.stop(index)
		  }, 1000)
      
    },
    // 抽奖结束会触发end回调
    endCallback (prize) {
      //领取奖品
      userInfo({lingqu: 1, act_id: this.info.id}).then(res=> {})
      //谢谢参与
      if(this.prize.result.name.indexOf('谢谢')>=0) {
            VanDialog.alert({
              // title: '活动规则',
              message: '谢谢您的参与，欢迎再接再厉',
            }).then(() => {
              this.show = false
              this.showNc = false
            })
            return
      }
    	this.prizeShow = true
    	this.show = false
    	
    },
  	handleStart () {
      //判断时间
      let now_time=new Date();
      let y = now_time.getFullYear();
      let MM = now_time.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = now_time.getDate();
      d = d < 10 ? ('0' + d) : d;
      let hh = now_time.getHours();
      hh = hh < 10 ? ('0' + hh) : hh;
      let m = now_time.getMinutes();
      m = m < 10 ? ('0' + m) : m;
      let ss = now_time.getSeconds();
      ss = ss < 10 ? ('0' + ss) : ss;
      let time_now = y + '-' + MM + '-' + d + ' ' + hh + ':' + m + ':' + ss  ;         
      console.log(time_now)
      if(time_now > this.info.end_date_time ){
        VanDialog.alert({
          title: '提醒',
          showCancelButton: true,
          message: '活动已经结束',
        })
        return false
      }
      var date = new Date()
      var h = parseInt(date.getHours().toString() + p(date.getMinutes()) + p(date.getSeconds()))
      var s = parseInt(this.info.start_time.replace(':', '').replace(':', ''))
      var e = parseInt(this.info.end_time.replace(':', '').replace(':', ''))
      if(h < s || h>e) {
        VanDialog.alert({
          title: '提醒',
          showCancelButton: true,
          message: '每日活动10:00点开始',
        })
        return 
      }
  		// this.show = true
  		// return 
  		var user = getUser()
      console.log(user)
      if(user.subscribe == 0) {
        this.guanzhu()
        return 
      }
  		//判断是否有手机号
  		if(!user.mobile) {
  			this.mobileShow = true
        setTimeout(() => {
          this.luosimao()
        }, 1000)
  			return 
  		}
      // 网易
      // if(!this.wangyiCaptcha) {
      //   this.showNc = true
      //   setTimeout(() => {
      //     this.wangyi()
      //   }, 1000)
      //   return
      // }
      // 阿里云
      // if(!this.chnc) {
      //   this.showNc = true
      //   setTimeout(() => {
      //     this.ncChou()
      //   }, 1000)
      //   return 
      // }
      // luosimao
      if(!this.luosimaoData) {
        this.showNc = true
        this.showOkLuomao = true
        setTimeout(() => {
          this.luosimao()
        }, 1000)
        return 
      }
  		prizeApi({id: this.info.id, nc: this.chnc, wangyiCaptcha: this.wangyiCaptcha, luosimaoData: this.luosimaoData}).then(res=> {
        this.resetLousimao()

        this.showNc = false
  			if(res.code == 201 && res.message.indexOf('手机号')>=0) {
  				this.show = false
  				this.mobileShow = true
          setTimeout(() => {
            // this.ncYan()
            this.luosimao()
          }, 1000)
  			}else if(res.code == 1){
  				this.prize = res
  				this.show = true
          this.showNc = false
          //只有弹出抽奖页面才获取底部中奖记录
          getPrizeLog(this.info.id).then(res=> {
            this.prizeList = res
          })

  			}else if(res.code == -1) {
          this.guanzhu()
        }else{
  				VanDialog.alert({
					  // title: '活动规则',
					  message: res.message,
					}).then(() => {
					  this.show = false
            this.showNc = false
					})
  			}
  		})
  		console.log('开始抽奖 ')
    },
    getResult (){
    	getResultPrize({id: this.info.id}).then(res=> {
    		if(!res.row) {
    			var message = '暂无中奖记录'
    			VanDialog.alert({
						message: message,
					})
    		}else{
    			this.logShow = true
    			this.logPrize = res
    		}
  		})
    },
  	onSubmit(values) {
      if(!this.luosimaoData) {
        Toast({
            message: '请先滑动验证',
        })
        return
      }
      // values.nc = this.nc
      values.luosimaoData = this.luosimaoData
  		Toast.loading({
		      message: '加载中...',
		      forbidClick: false,
		      loadingType: 'spinner',
		      duration: 10000
		  })
      userInfo(values).then(res=> {
      	Toast.clear()
        if(res.code == -1) {
          return this.guanzhu()
        }
      	Toast({
		        message: res.message,
		    })
		    if(res.code == 1) {
		    	setUser(res.user)
		    	this.user = res.user
		    	this.mobileShow = false
		    	this.ok = false
          this.resetLousimao()
          document.getElementsByClassName('.savemobile')
		    }
      })
    },
    onSubmitInfo (e) {
    	Toast.loading({
		      message: '加载中...',
		      forbidClick: false,
		      loadingType: 'spinner',
		      duration: 10000
		  })
      let data = {
        address: e.addressDetail,
        city: e.city,
        country: e.country,
        county: e.county,
        province: e.province,
        tel: e.tel,
        name: e.name,
        areaCode: e.areaCode,
        info: 1
      }
      userInfo(data).then(res=> {
      	Toast.clear()
        VanDialog.alert({
          message: res.message,
        })
		    if(res.code == 1) {
		    	setUser(res.user)
		    	this.user = res.user
		    	this.infoShow = false
		    	this.prizeShow = false
		    }
      })
    },
    clickOverlay() {
    	VanDialog.alert({
			  title: '提醒',
			  showCancelButton: true,
			  message: '只有完善信息才能领奖，您确定要关闭吗？',
			}).then(() => {
			  this.infoShow = false
			}).catch(() => {
				this.infoShow = true
		    // on cancel
		  })
    },
  	guize() {
  		VanDialog.alert({
			  // title: '活动规则',
			  message: this.info.desc,
			}).then(() => {
			  // on close
			})
  	},
    guanzhu() {
      let img = require('../assets/qrcode.jpg')
      VanDialog.alert({
        title: '温馨提示',
        message: '<p>关注“奇瑞汽金”官方微信公众号，回复“奇瑞汽金，专注美好车生活”唤醒抽奖助手，输入关键词“贺新春”参与抽奖。<span style="color: red">关注后请刷新页面参加活动</span></p><img align="center" src="'+ img +'" />',
      }).then(() => {
        // on close
      })
    },
  	sendMsg () {
  		if(!this.mobile) {
  			Toast({
		        message: '请填写手机号',
		    })
  			return 
  		}
  		Toast.loading({
		      message: '加载中...',
		      forbidClick: false,
		      loadingType: 'spinner',
		      duration: 10000
		  })
		  sendMessage({mobile: this.mobile}).then(res=> {
		  	Toast.clear()
		  	if(res.code == 1) {
		  		this.ok = true
		  		this.time = 1000 * 60
		  	}else if(res.code == -1) {
          return this.guanzhu()
        }
		  	Toast({
		        message:  res.cc?(res.message + res.cc):res.message,
		    })
		  })
  	},
  	finish() {
  		this.ok = false
  	},
    onChangeDetail(){},
    ncYan(){
      var nc_token = ["FFFF0N0000000000A716", (new Date()).getTime(), Math.random()].join(':');
      var that = this
      var nc=NoCaptcha.init({
          renderTo: '#nc',
          appkey: 'FFFF0N0000000000A716', 
          scene: 'nc_message_h5',
          token: nc_token,
          trans: {"key1": "code200"},
          elementID: ["usernameID"],
          is_Opt: 0,
          language: "cn",
          timeout: 10000,
          retryTimes: 5,
          errorTimes: 5,
          inline:false,
          apimap: {
              // 'analyze': '//a.com/nocaptcha/analyze.jsonp',
              // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
          },
          bannerHidden:false,
          initHidden:false,
          callback: function (data) {
            that.nc = {
              nc_token, data
            }
          },
          error: function (s) {
          }
      });
      NoCaptcha.setEnabled(true);
      nc.reset();//请务必确保这里调用一次reset()方法

      NoCaptcha.upLang('cn', {
          'LOADING':"加载中...",//加载
          'SLIDER_LABEL': "请向右滑动验证",//等待滑动
          'CHECK_Y':"验证通过",//通过
          'ERROR_TITLE':"非常抱歉，这出错了...请刷新页面",//拦截
          'CHECK_N':"验证未通过", //准备唤醒二次验证
          'OVERLAY_INFORM':"经检测你当前操作环境存在风险，请输入验证码",//二次验证
          'TIPS_TITLE':"验证码错误，请重新输入"//验证码输错时的提示
      });
    },
    ncChou(){
      var nc_token = ["FFFF0N0000000000A716", (new Date()).getTime(), Math.random()].join(':');
      var that = this
      var nc=NoCaptcha.init({
          renderTo: '#chouNc',
          appkey: 'FFFF0N0000000000A716', 
          scene: 'nc_activity_h5',
          token: nc_token,
          trans: {"key1": "code200"},
          elementID: ["usernameID"],
          is_Opt: 0,
          language: "cn",
          timeout: 10000,
          retryTimes: 5,
          errorTimes: 5,
          inline:false,
          apimap: {
              // 'analyze': '//a.com/nocaptcha/analyze.jsonp',
              // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
          },
          bannerHidden:false,
          initHidden:false,
          callback: function (data) {
            that.chnc = {
              nc_token, data
            }
            that.showNc = false
            that.handleStart()
          },
          error: function (s) {
          }
      });
      NoCaptcha.setEnabled(true);
      nc.reset();//请务必确保这里调用一次reset()方法

      NoCaptcha.upLang('cn', {
          'LOADING':"加载中...",//加载
          'SLIDER_LABEL': "请向右滑动验证",//等待滑动
          'CHECK_Y':"验证通过",//通过
          'ERROR_TITLE':"非常抱歉，这出错了...请刷新页面",//拦截
          'CHECK_N':"验证未通过", //准备唤醒二次验证
          'OVERLAY_INFORM':"经检测你当前操作环境存在风险，请输入验证码",//二次验证
          'TIPS_TITLE':"验证码错误，请重新输入"//验证码输错时的提示
      });
    },
    wangyi(){
      let that = this
      var captchaIns;
            initNECaptcha({
                element: '#chouNc',
                captchaId: '0cb1ec27d091466bb89e985272f9ce7b',
                width: '320px',
                mode: 'popup',
                onVerify: function (err, data) {
                    /**
                     * 第一个参数是err（Error的实例），验证失败才有err对象
                     * 第二个参数是data对象，验证成功后的相关信息，data数据结构为key-value，如下：
                     * {
                     *   validate: 'xxxxx' // 二次验证信息
                     * }
                    **/
                    if (err) {
                      Toast({
                          message:  '验证失败，请刷新页面后再试',
                      })
                      return
                    }  // 当验证失败时，内部会自动refresh方法，无需手动再调用一次
                    that.wangyiCaptcha = data.validate
                    that.showNc = false
                    that.handleStart()
                }
            }, function onload (instance) {
                captchaIns = instance
                that.captchaIns = captchaIns
            }, function onerror (err) {
                console.warn(err)
            })
            var loginBtn = document.getElementById('chouNc')
            loginBtn.addEventListener('click', function () {
                captchaIns && captchaIns.popUp()
            })
    },
    luosimao(){
      //判断是否已经存在
      var els = document.getElementsByName("luotest_response");
      if(els.length>0) {
        LUOCAPTCHA.reset()
        this.showOkLuomao = false
        return false
      }
      const self = this;
      this.dynamicLoadJs("//captcha.luosimao.com/static/js/api.js", res=> {
        self.showOkLuomao = false
      });
      window.getResponse = (resp) => {
        var els =document.getElementsByName("luotest_response");
        if (els.length === 1 && els[0].value === resp) {
          // console.log(resp)
          self.luosimaoData = resp
          // self.showNc = false
          // self.handleStart()
          // self.$emit("success", resp);
        } else {
          Toast({
            message:  '验证失败，请刷新页面后再试',
          })
          // eslint-disable-next-line
          LUOCAPTCHA && LUOCAPTCHA.reset();
        }
      }
      window.getResponseChou = (resp) => {
        var els =document.getElementsByName("luotest_response");
        if (els.length === 1 && els[0].value === resp) {
          // console.log(resp)
          self.luosimaoData = resp
          self.showNc = false
          self.handleStart()
          // self.$emit("success", resp);
        } else {
          Toast({
            message:  '验证失败，请刷新页面后再试',
          })
          // eslint-disable-next-line
          LUOCAPTCHA && LUOCAPTCHA.reset();
        }
      }
    },
    dynamicLoadJs: function(url, callback) {
      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      if (typeof callback == "function") {
        script.onload = script.onreadystatechange = function() {
          if (
            !this.readyState ||
            this.readyState === "loaded" ||
            this.readyState === "complete"
          ) {
            callback();
            script.onload = script.onreadystatechange = null;
          }
        };
      }
      head.appendChild(script);
    },
    resetLousimao(){
      this.luosimaoData = ''
      // if(document.getElementsByClassName('l-captcha').length == 0)return false

      document.getElementsByClassName('l-captcha').forEach(function(ele){
        // console.log(ele)
        ele.innerHTML = ''
      })
    },
  	getShare() {
      share({url: location.href.split('#')[0]}).then(res=> {
        wx.config(JSON.parse(res))
        let _this = this
        console.log(111222)
        wx.error(function(res){
				  console.log(res)
				});
        wx.ready(function(){
        	console.log(wx)
        	console.log(_this.info)
          let title = _this.info.share_title
          let desc = _this.info.share_desc
          // console.log(title)
          // let img = _this.info.share_img
          // let img = 'http://' + window.location.host + require('../assets/' + _this.info.id + '.png')
          // console.log(img)
          let img = 'http://qr.hfyggy.com/share.png'
          let link = getIndexUrl(_this.info.id)
          
          // console.log(link)
          wx.updateAppMessageShareData({ 
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: img, // 分享图标
            success: function () {
            	// console.log(1)
              // 设置成功
            }
          })
          wx.updateTimelineShareData({ 
            title: title, // 分享标题
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: img, // 分享图标
            success: function () {
              // 设置成功
            }
          })
       //    wx.hideMenuItems({
			    //    menuList: ["menuItem:copyUrl"] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
			   	// });
          
        })
      })
    }
  }
};
</script>
<style scoped>
.home{
  background: url(../assets/bg.jpg) no-repeat center;
  background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
}
.act{ width: 70%; margin: 5% auto; font-size: 12px;}
.button{ margin-top: 3%;  }
.button-color {color:#bc1c28!important;    padding: 0 30px; margin: 0 20px; font-weight: bolder;font-size: 20px;}
.tuzi{ position: absolute; bottom: 3%; z-index: -1; }
.tuzi img{ width: 75%; }
.logo{margin-top: 12%}
.list-prize{ width: 55%; margin: 13px auto; font-size: 12px;}
.list-prize li {list-style: none; text-align: left; line-height: 25px; color: #fbdbaa}

@media (min-width:320px){
  .button-color{
		padding: 0 20px;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px)and (-webkit-device-pixel-ratio: 2)  {
  .tuzi{ bottom: 1%; }
  .tuzi img{ width: 45%; }
  .logo{margin-top: 11%}
  .list-prize{ margin: 10px auto; }
  .act{margin: 3% auto;}
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-device-pixel-ratio: 2) {
  .logo{margin-top: 12%}
  .tuzi{ bottom: 1%; }
  .tuzi img{ width: 55%; }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-device-pixel-ratio: 2) {
    .tuzi{ bottom: 2%; }
    .tuzi img{ width: 75%; }
  }
@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-device-pixel-ratio: 3) {
    .tuzi{ bottom: 2%; }
    .tuzi img{ width: 75%; }
  }
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-device-pixel-ratio: 3) {
    .tuzi{ bottom: 1%; }
    .tuzi img{ width: 65%; }
  }

@media (max-height:700px){
  .tuzi{ bottom: 1%; }
  .tuzi img{ width: 55%; }
}
/*
@media (max-height:723px){
  .tuzi{ bottom: 1%; }
  .tuzi img{ width: 60%; }
}*/

/*@media (min-width:370px){
  .tuzi img{ width: 55%; }
}
@media (min-width:400px){
  .tuzi img{ width: 75%; }
}*/
.mobile-width{width: 80%;}
.form-body{ padding: 20px 0px; }
.form-mobile{ }
.wapp{
    position: relative;
    top: 20%;
    margin: 20px auto;
}
.jilu{ position: fixed;top: 1%; right: 10px; color: #fff; }
.daojishi{ background: #ffb971; line-height: 30px; width: 80%;  margin: 0 auto;    margin-top: 170px; color: #f32f27; }

.scroll-wrap{
  width: 90%;
  height: 50px;
  overflow: hidden;
  font-size: 16px;
 	line-height: 50px;
 	color: #fff;
 	margin: 0 auto; 
 	margin-top: 10px;

 	text-align: center;
}

.scroll-content{
  position: relative;
  transition: top 0.5s;

  li{
    line-height: 50px;
    text-align: center;
  }
}
.mobile-prize{ width: 80%; background: none; }
.pname{ position: absolute; top: 2.8rem; font-size: 20px; width: 100%; text-align: center; color: #f32f27; font-weight: 800 }
.fname{ position: absolute; bottom: 1.5rem; font-size: 20px; width: 100%; text-align: center; color: #fff;font-weight: 800 }

.mobile-log-show{ background: #fff; padding: 20px; width: 80%; }
.mobile-log-show p{ line-height: 30px; }
.mobile-log-show h2{ font-size: 20px;  line-height: 40px;}
.beizhu{
  font-size: 14px;
    text-align: left;
    line-height: 25px;
    width: 80%;
    margin: 0 auto;
    color: #f35959;
}
.wuliu p{
  text-align: left;
  font-size: 12px;
}
.wuliu{
  text-align: right;
}
</style>
<style>
.van-dialog{z-index: 200000000022!important}
	.van-dialog__message{ text-align: left; }
	.form-body .van-field__body{border: 1px solid #e3e1e1;}
	.wapp .myLucky{ margin:  0 auto; }
.beizhushow{ position: absolute; width: 100%; bottom: 0px; }

._nc .stage1 .label, ._nc .stage1 .track div, ._nc .stage1 .label, ._nc .stage1, ._nc .stage1 .button{
  height: 38px !important;
  line-height: 38px !important;
      font-size: 14px;
}

   ._nc .stage1 .slider {
        height: 38px !important;
        line-height: 38px !important;
        border-radius: 0px !important;
        box-shadow: 0 0 3px #999 !important;
        background-color: #ddd !important;
      }
      ._nc .stage1 .track div {
        border-radius: 0 !important;
        color: #fff !important;
      }
      ._nc .stage1 .bg-green {
        background-color: rgb(238, 78, 86) !important;
      }
      ._nc .stage1 .bg-red {
        background-color: #78c430 !important;
      }
</style>
