<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { getAcitvityInfo, share } from '@/api/app'

export default {
  name: 'app',
  data() {
    return {
      title: this.$route.meta.title,
      info: {}
    }
  },
  created() {
    this.initFontSize()
  },
  methods: {
    initFontSize() {
      let width = document.documentElement.clientWidth || document.body.clientWidth
      // 设计稿750px 2倍屏 html的font-size:50px
      let R_font = (width * 2) / 750 * 50
      document.querySelector('html').style.fontSize = `${R_font}px`
    }
  },
  computed:{
    key(){
      return this.$route.path + Math.random();
    }
  }

}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
