/*
* @Author: jack
* @Date:   2021-12-14 12:28:25
* @Last Modified by:   jack
* @Last Modified time: 2023-01-13 09:21:38
*/
const TokenKey = 'shao-manage-app-qirui'


export function getApp() {
  let app = localStorage.getItem(TokenKey)
  if(app)return JSON.parse(app)
  return null
}

export function setApp(info) {
  removeApp()
  return localStorage.setItem(TokenKey, JSON.stringify(info))
}

export function removeApp() {
  return localStorage.removeItem(TokenKey)
}

export function getAppId() {
  return localStorage.getItem(TokenKey + '_id')
}

export function setAppId(id) {
  removeAppId()
  return localStorage.setItem(TokenKey + '_id', id)
}

export function removeAppId() {
  return localStorage.removeItem(TokenKey + '_id')
}

export function getAppVersion() {
  return localStorage.getItem(TokenKey + '_Version')
}

export function setAppVersion(v) {
  removeAppVersion()
  return localStorage.setItem(TokenKey + '_Version', v)
}

export function removeAppVersion() {
  return localStorage.removeItem(TokenKey + '_Version')
}

export function getQueryVariable(variable)
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}
export function timestampToTime(timestamp) {
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        return Y+M+D+h+m+s;
}

export function oauthUrl(id){
  var url = urlencode(getIndexUrl(id))
  console.log(url)
	return 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9f392f592b30ce7d&redirect_uri='+url+'&response_type=code&scope=snsapi_userinfo&state=286aff33adc2a09f249d184240c00741&connect_redirect=1#wechat_redirect'
}
function urlencode (str) {  
    str = (str + '').toString();   

    return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').  
    replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');  
}
export function getIndexUrl(id) {
	return 'http://' + window.location.host + '/?id=' + id
}