/*
* @Author: jack
* @Date:   2021-12-14 12:26:26
* @Last Modified by:   jack
* @Last Modified time: 2023-01-12 15:54:34
*/

const TokenKey = 'shao-manage-user-qirui'

export function getUser() {
  let app = localStorage.getItem(TokenKey)
  if(app)return JSON.parse(app)
  if(process.env.NODE_ENV == "development") {
    return process.env.VUE_APP_USER
  }
  return null
}

export function setUser(user) {
	removeUser()
  return localStorage.setItem(TokenKey, JSON.stringify(user))
}

export function removeUser() {
  return localStorage.removeItem(TokenKey)
}