/*
* @Author: jack
* @Date:   2021-12-14 12:29:03
* @Last Modified by:   jack
* @Last Modified time: 2021-12-27 17:02:57
*/
export function getPrizes(prizes) {
	var list = []
	var background = ['#f3953e', '#fffbaa']
	for (var i in prizes) {
		list.push({
			item: prizes[i],
			fonts: [{ text: '', top: '10%', fontColor: '#f32f27', fontSize: '14px' }],
			// fonts: [{ text: prizes[i]['name'], top: '10%', fontColor: '#f32f27', fontSize: '14px' }],
			// background: (i%2)==0?background[0]:background[1],
			// imgs: [{ src: require('../assets/1-1.png'), width: '70%', top: '0%' }]
		})
	}
	return list
}

export function p(d) {
	return d < 10 ? ('0' + d) : d.toString()
}