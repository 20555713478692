import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { getToken, removeToken } from '@/utils/auth'
import { removeUser } from '@/utils/user'
import { oauthUrl, setAppId, getAppVersion, setAppVersion, getAppId } from '@/utils/app'
import VueWechatTitle from 'vue-wechat-title' 

import '@/assets/common.css'
import 'vant/lib/toast/style'
import 'vant/lib/button/style'

Vue.use(VueWechatTitle)
//版本号不一样清楚缓存
var v = getAppVersion()
if(v && v != process.env.VUE_APP_Version) {
	removeUser()
	removeToken()
	window.location.href = oauthUrl(getAppId())
}
setAppVersion(process.env.VUE_APP_Version)

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
     document.title = to.meta.title;
  }
  let href = window.location.href
  let token = getToken()
  if (!href.includes("code=") && !token) {
  	console.log(token)
  	setAppId(to.query.id)
    window.location.href = oauthUrl(to.query.id)
    next(false)
  }else{
  	next()
  }
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
